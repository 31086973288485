.text-input-container {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.text-input-label {
  margin-bottom: 4px;
  color: #333; /* Or any color you prefer */
  font-size: 14px;
  font-weight: bold;
}

.text-input {
  padding: 8px;
  border: 1px solid #ccc; /* Style as you like */
  border-radius: 4px;
  outline: none;
}

.text-input:disabled {
  background-color: #e9e9e9;
}

/* You can add hover effects, focus effects, etc. */
.text-input:hover:not(:disabled) {
  border-color: #888;
}

.text-input:focus {
  border-color: #555;
}
